import React, { Component } from 'react';
import Ticker from 'react-ticker';
import { graphql } from 'gatsby';
import {
  Layout,
} from '../components';
import {
  Wrapper,
  HtmlContent,
  Buttons,
} from '../components/common';
import './404.scss';

const FourOhFourSVG = () => {
  const brokenLetter = Math.floor((Math.random() * 3) + 1);
  const isBroken = Boolean(Math.floor((Math.random() * 3) + 1) === 3);
  const flickerDelay = Math.random().toFixed(3) / 2;
  return (
    <div className={`four-oh-four-svg${isBroken ? ' will-flicker' : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 110">
        <g fillRule="nonzero" strokeWidth="2">
          <path
            className={brokenLetter === 1 ? 'flickers' : ''}
            style={{
              animationDelay: `${flickerDelay}s`,
            }}
            d="M38.35 106V85.75H1v-8.7L37.45 1h18.9v68.25h9.9v16.5h-9.9V106h-18zM22 69.25h16.35V32.5L22 69.25z"
          />
          <path
            className={brokenLetter === 2 ? 'flickers' : ''}
            style={{
              animationDelay: `${flickerDelay}s`,
            }}
            d="M201.425 107V86.75h-37.35v-8.7L200.525 2h18.9v68.25h9.9v16.5h-9.9V107h-18zm-16.35-36.75h16.35V33.5l-16.35 36.75z"
          />
          <path
            className={brokenLetter === 3 ? 'flickers' : ''}
            style={{
              animationDelay: `${flickerDelay}s`,
            }}
            d="M115.1625 108.4c-21.3 0-31.65-17.7-31.65-53.7S93.8625 1 115.1625 1c21.3 0 31.65 17.7 31.65 53.7s-10.35 53.7-31.65 53.7zm0-16.95c9.45 0 13.5-11.1 13.5-36.75s-4.05-36.75-13.5-36.75c-9.6 0-13.5 11.1-13.5 36.75s3.9 36.75 13.5 36.75z"
          />
        </g>
      </svg>
    </div>
  );
};

const FourOhFourTicker = () => (
  <div className="four-oh-four-ticker">
    <Ticker>
      {() => (
        <FourOhFourSVG />
      )}
    </Ticker>
  </div>
);

class FourOhFour extends Component {
  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      headline,
      description,
      buttons,
    } = pageData;
    const seoData = {
      metaTitle: `Page Not Found | Women's & Girls' Emergency Centre`,
      metaDescription: '',
      banner: '',
    };
    return (
      <Layout location={location} seoData={seoData}>
        <section className="four-oh-four-page">
          <FourOhFourTicker />
          <Wrapper isBound>
            <div className="text-container">
              <h1 className="headline">{headline.text}</h1>
              <HtmlContent
                className="description"
                content={description.html}
              />
              {buttons && buttons.length > 0 && (
                <Buttons
                  buttons={buttons}
                  theme="primary"
                />
              )}
            </div>
          </Wrapper>
        </section>
      </Layout>
    );
  }
}

export default FourOhFour;

export const pageQuery = graphql`
query FourOhFourQuery {
  page: prismicFourOhFour {
    data {
      headline {
        text
      }
      description {
        html
      }
      buttons {
        buttonLabel: button_label {
          text
        }
        buttonLink: button_link {
          slug
          url
          type
          linkType: link_type
        }
      }
    }
  }
}
`;
